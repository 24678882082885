import currencyJs from "currency.js";
import { formatDistanceStrict } from "date-fns";
/**
 * GET THE DIFFERENCE DATE FORMAT
 * @param  DATE | NUMBER | STRING
 * @returns FORMATTED DATE STRING
 */

function getDateDifference(date) {
  const distance = formatDistanceStrict(new Date(), new Date(date));
  return distance + " ago";
}
/**
 * RENDER THE PRODUCT PAGINATION INFO
 * @param page - CURRENT PAGE NUMBER
 * @param perPageProduct - PER PAGE PRODUCT LIST
 * @param totalProduct - TOTAL PRODUCT NUMBER
 * @returns
 */


function renderProductCount(page, perPageProduct, totalProduct) {
  let startNumber = (page - 1) * perPageProduct;
  let endNumber = page * perPageProduct;

  if (endNumber > totalProduct) {
    endNumber = totalProduct;
  }

  return `Showing ${startNumber - 1}-${endNumber} of ${totalProduct} products`;
}
/**
 * CALCULATE PRICE WITH PRODUCT DISCOUNT THEN RETURN NEW PRODUCT PRICES
 * @param  price - PRODUCT PRICE
 * @param  discount - DISCOUNT PERCENT
 * @returns - RETURN NEW PRICE
 */


function calculateDiscount(price, discount) {
  const afterDiscount = Number((price - price * (discount / 100)).toFixed(2));
  return currency(afterDiscount);
}
/**
 * CHANGE THE CURRENCY FORMAT
 * @param  price - PRODUCT PRICE
 * @param  fraction - HOW MANY FRACTION WANT TO SHOW
 * @returns - RETURN PRICE WITH CURRENCY
 */


function currency(price, fraction = 2) {
  const formatCurrency = currencyJs(price).format({
    precision: fraction,
    symbol: 'RM',
  });
  return formatCurrency;
}


function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

const generateStatus = (statusKey) => {
    switch (statusKey) {
        case 'pending-payment':
            return "Pending payment";
        case 'pending-shipment':
            return "Pending shipment";
        case 'pending-delivery':
            return "Pending delivery";
        case 'completed':
            return "Completed";
        case 'refund-request':
            return "Refund requested";
        case 'refund-approved':
            return "Refund approved";
        case 'refund-cancelled':
            return "Refund cancelled";
        case 'refunded':
            return "Refunded";
    }
}

const getColor = status => {
    switch (status) {
        case "pending-payment":
            return "primary";

        case "pending-shipment":
            return "secondary";

        case "pending-delivery":
            return "secondary";

        case "completed":
            return "success";

        case "Cancelled":
            return "primary";

        default:
            return "default";
    }
};

const checkAddedQuantity = (products, cart, categoryId) => {
    let duplicates = 0;
    return cart.filter(item => {
        const product = products.find(product => product.product.id === item.id);
        if(item.qty>1){
            duplicates += item.qty - 1;
        }
        return product?.product?.categories.includes(categoryId);
    }).length + duplicates
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const generateProductProperties = (variant) => {
    let text = '';
    let count = 0
    for (const type in variant) {
        const value = variant[type];
        text += capitalizeFirstLetter(type) + ': ' + value;
        count++;
        if (Object.keys(variant).length !== count) text += ', ';
    }
    return text;
}

const getVariant = (selectedVariant = {}, variants) => variants.filter(variant => Object.keys(selectedVariant).every((key) => selectedVariant[key] === variant['variantObject'][key]))?.[0];

const states = [
    { key: "jhr", label: "Johor" },
    { key: "kdh", label: "Kedah" },
    { key: "ktn", label: "Kelantan" },
    { key: "mlk", label: "Melaka" },
    { key: "nsn", label: "Negeri Sembilan" },
    { key: "phg", label: "Pahang" },
    { key: "prk", label: "Perak" },
    { key: "pls", label: "Perlis" },
    { key: "png", label: "Pulau Pinang" },
    { key: "sgr", label: "Selangor" },
    { key: "trg", label: "Terengganu" },
    { key: "kul", label: "Kuala Lumpur" },
    { key: "pjy", label: "Putra Jaya" },
    { key: "srw", label: "Sarawak" },
    { key: "sbh", label: "Sabah" },
    { key: "lbn", label: "Labuan" }
];
const getStateLabel = (key) => {
    const state = states.find(state => state.key === key);
    return state ? state.label : null;
}

const couriersList = [
    {
        name: "J&T Express",
        cid: "85",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/J&T_Express.jpg",
    },
    {
        name: "Skynet",
        cid: "6",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/Skynet.jpg",
    },
    {
        name: "Flash Express",
        cid: "90",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/Flash_Express.jpg",
    },
    {
        name: "LineClear Express",
        cid: "1001",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/LineClear_Express.jpg",
    },
    {
        name: "City-Link Express",
        cid: "1000",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/City-Link_Express.jpg",
    },
    {
        name: "Aramex",
        cid: "23",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/Aramex.jpg",
    },
    {
        name: "DHL eCommerce",
        cid: "22",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/DHLeC.jpg",
    },
    {
        name: "Ninjavan",
        cid: "93",
        logo: "https://s3-ap-southeast-1.amazonaws.com/easyparcel-static/Public/source/general/img/couriers/Ninjavan.jpg",
    },
]

export { currency, getDateDifference, calculateDiscount, renderProductCount, convertTZ, generateStatus, getColor, checkAddedQuantity, capitalizeFirstLetter, generateProductProperties, getVariant, states, getStateLabel, couriersList };
